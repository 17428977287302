import React from "react"
import { BenefitsGrid } from "../components/BenefitsGrid"
import Layout from "../components/Layout"
import { ContentSubHeading, ContentHeading } from "../components/styles"
import { benefits } from "../data/teams"

export default function Home() {
  const getRandomInt = (min = 0, max = 10) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  return (
    <>
      <Layout title="404. Page Not Found | Pricelist">
        <div class="relative px-4 sm:px-6 lg:px-8">
          <div class="text-lg max-w-prose mx-auto">
            <h1>
              <ContentSubHeading>404. Page Not Found</ContentSubHeading>
              <ContentHeading>
                We can't find the page you're looking for...
              </ContentHeading>
            </h1>
          </div>

          <div className="max-w-prose text-center text-2xl mx-auto mt-20 text-gray-800">
            ... but consider some of the benefits Pricelist can provide to your
            company:
          </div>

          <BenefitsGrid benefits={benefits} />
        </div>
      </Layout>
    </>
  )
}
